import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  QuotesSection,
  ReasonsToBelieveSection,
  OneAppSection,
  FinancialServicesSection,
  AetnaHero,
} from 'src/sections';
import { Page, Section, Lineup, Zip } from 'src/components';
import SessionContext from 'src/context/SessionContext';

const SImg = styled.img`
  filter: var(--svgFilter);
  height: 72px;
  margin-bottom: 12px;
  opacity: 0.5;
  @media (prefers-color-scheme: dark) {
    filter: var(--svgFilter) saturate(0%) brightness(200%);
  }
  @media (max-width: 800px) {
    margin: 0;
  }
`;

const HomePage = ({ pageContext = {} }) => {
  const { source: _source, code, name, slug, personas = [] } = pageContext;
  const { source, signupUrl } = useContext(SessionContext);

  return (
    <Page
      explicitBannerColor={!!code}
      name={name || source}
      kw={[source, name, ...personas]}
      path={slug || code}
      signupUrl={signupUrl}
      navBorder={false}
    >
      <AetnaHero />
      <Section small>
        <Lineup height={48} numPerRow={8}>
          <SImg loading="lazy" src="/logos/standard/nyt.webp" />
          <SImg loading="lazy" src="/logos/standard/wsj.webp" />
          <SImg loading="lazy" src="/logos/standard/techcrunch.webp" />
          <SImg loading="lazy" src="/logos/standard/forbes.webp" />
          <SImg loading="lazy" src="/logos/standard/fortune.webp" />
          <SImg loading="lazy" src="/logos/standard/bi.png" />
          <SImg loading="lazy" src="/logos/standard/realsimple.webp" />
          <SImg loading="lazy" src="/logos/standard/entrepreneur.webp" />
        </Lineup>
      </Section>
      <FinancialServicesSection variation="attest" />

      <ReasonsToBelieveSection showCTA />
      <QuotesSection />
      <OneAppSection title="Start shopping today">
        <Zip cta="Get started" />
      </OneAppSection>
    </Page>
  );
};

export default HomePage;
